html, 
body {
    height: 100%;
    scroll-padding-top: 50px
}

.Site-content {
  padding-top: 50px;
  margin-bottom: 100px;
}

.card-deck-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.card-header-labels {
  font-size: 11px;
}

.card-title-source {
  color: gray;
  font-size: 12px;
}

.card-text {
  display: inline-block;
  white-space: wrap;
  max-height: 70px;
  overflow: hidden;
}

.card-text:hover {
  overflow: visible;
}

.leaflet-container {
  height: 600px;
  width: 100%;
}

.align-middle {
  vertical-align: middle;
}

.toast-message {
  position: absolute;
  top: 100;
  /* left: 0; */
}